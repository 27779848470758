<template>
  <div>
    <v-snackbar
      content-class="centered"
      absolute
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">{{ $tc("sort.title") }}</span>
        <v-btn
          style="right: 10px; top: 10px; border-radius: 30px !important"
          icon
          color="primary"
          :loading="loading"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="height-modal">
        <p v-if="items.length == 0" class="mt-10" style="text-align: center">
          <span> {{ $tc("sort.empty") }} 😔</span>
        </p>

        <draggable
          class="mt-5"
          v-else
          v-bind="dragOptions"
          @change="onChange"
          ghost-class="ghost"
          v-model="items"
          @start="drag = true"
          @end="drag = false"
        >
          <transition-group type="transition" name="flip-list">
            <v-list-item
              style="cursor: move"
              class="list-group-item"
              v-for="item in items"
              :key="item['.key']"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>fas fa-bars</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </transition-group>
        </draggable>
      </v-card-text>

      <v-card-actions class="pa-3">
        <v-btn
          :disabled="!changed"
          color="primary"
          :loading="loading"
          block
          height="50"
          class="mb-5"
          @click="updatePosition()"
        >
          {{ $tc("button.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import draggable from "vuedraggable";
export default {
  name: "Sort",
  props: ["source"],
  components: {
    draggable,
  },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      loading: true,
      items: [],
      changed: false,
      drag: false,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    sortedArrayByPosition: function () {
      function compare(a, b) {
        if (a.position && b.position && a.position < b.position) return -1;
        if (a.position && b.position && a.position > b.position) return 1;
        return 0;
      }
      return this.localCategories.sort(compare);
    },
  },
  async mounted() {
    this.items = JSON.parse(JSON.stringify(this.source));
    this.loading = false;
  },
  methods: {
    updatePosition() {
      this.loading = true;
      this.$emit("updatedItems", this.items);
    },
    onChange() {
      this.changed = true;
    },
  },
};
</script>


<style scoped>
.height-modal {
  height: 50vh;
  max-height: 50vh;
  min-height: 50vh;
  overflow-y: scroll;
}
.list-group-item {
  border-bottom: 1px solid rgba(128, 128, 128, 0.425);
}
.ghost {
  opacity: 0.5;
  background: #008bbf;
}
.flip-list-move {
  transition: transform 0.5s;
}
</style>