<template>
  <div>
    <v-snackbar content-class="centered" top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline"> {{$tc('add_products.title', subcategory.name , {subcategory : subcategory.name } )}}</span>
        <v-btn
          style="right: 10px; top: 10px;  border-radius : 30px!important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-5">
          <v-row class="pa-0 ma-0">
            <v-col cols="6" sm="6" md="6">
              <div class="cards-container">
                <p class="font-weight-bold">
                  {{$tc('add_products.all_products')}} ({{ getProducts.length }})
                </p>
                <v-text-field
                  v-model="searchProduct"
                  clearable
                  rounded
                  :loading="loading"
                  flat
                  filled
                  hide-details
                  :label="$tc('button.search')"
                  prepend-inner-icon="fa-search"
                  class="d-md-flex"
                />

                <v-list color="card" three-line>
                  <draggable
                    @change="leftSideEdited"
                    itemKey="id"
                    class="list-group"
                    :list="getProducts"
                    group="files"
                  >
                    <template v-for="item in getProducts">
                      <v-list-item class="list-item" :key="item.id">
                        <img
                          class="image"
                          @click="showFile(item)"
                          :src="item.screenshot"
                        />
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="item.name"
                          ></v-list-item-title>
                          <v-list-item-subtitle>
                            <div class="description d-none d-md-block d-sm-bock">
                              <span
                                class="mr-3"
                                v-for="(item, index) of item.characteristics"
                                :key="index"
                              >
                                <v-chip>
                                  {{ item.name }} : {{ item.value }}
                                </v-chip>
                              </span>
                            </div>
                            <div class="description">
                              {{ item.description }}
                              >
                            </div>
                            <div class="mt-3 d-none d-md-block d-sm-bock">
                              {{$tc('videos.uploaded_at')}}
                              <span class="font-weight-bold">
                                {{ item.createdAt | filterDate }}
                              </span>
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </draggable>
                </v-list>
              </div>
            </v-col>

            <v-col cols="6" sm="6" md="6">
              <div class="cards-container">
                <p class="font-weight-bold">
                  {{$tc('add_products.added_products')}} ({{ getProductsAdded.length }})
                </p>
                <v-text-field
                  v-model="searchProductsAdded"
                  clearable
                  :loading="loading"
                  rounded
                  flat
                  filled
                  hide-details
                  :label="$tc('button.search')"
                  prepend-inner-icon="fa-search"
                  class="d-md-flex"
                />

                <v-list color="card" three-line>
                  <draggable
                    @change="rightSideEdited"
                    itemKey="id"
                    class="list-group"
                    :list="getProductsAdded"
                    group="files"
                  >
                    <template v-for="item in getProductsAdded">
                      <v-list-item class="list-item" :key="item.id">
                        <img
                          class="image"
                          @click="showFile(item)"
                          :src="item.screenshot"
                        />
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="item.name"
                          ></v-list-item-title>
                          <v-list-item-subtitle>
                            <div class="description d-none d-md-block d-sm-bock">
                              <span
                                class="mr-3"
                                v-for="(item, index) of item.characteristics"
                                :key="index"
                              >
                                <v-chip>
                                  {{ item.name }} : {{ item.value }}
                                </v-chip>
                              </span>
                            </div>
                            <div class="description">
                              {{ item.description }}
                              >
                            </div>
                            <div class="mt-3 d-none d-md-block d-sm-bock">
                              {{$tc('videos.uploaded_at')}}
                              <span class="font-weight-bold">
                                {{ item.createdAt | filterDate }}
                              </span>
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </draggable>
                </v-list>
              </div>
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" md="12">
              <v-row justify="end">
                <v-btn @click="save()" :loading="loading" color="buttons"
                  >{{$tc('button.save')}}</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog
      hide-overlay
      fullscreen
      max-width="60%"
      v-if="viewProductDialog"
      v-model="viewProductDialog"
    >
      <view-file
        :url="selectedProduct.images[0].url"
        type="image"
        @cancel="viewProductDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import draggable from "vuedraggable";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import viewFile from "@/components/view-file";

export default {
  name: "add-playlist",
  props: ["category", "subcategory"],
  components: {
    draggable,
    viewFile,
  },
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      loading: true,
      playlist: {
        name: "",
        description: "",
        imageDuration: 10,
      },
      products: [],
      productsAdded: [],
      searchProduct: "",
      searchProductsAdded: "",
      selectedProduct: null,
      viewProductDialog: false,
    };
  },
  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("L");
    },
    filterSize(size) {
      return `${(size / 1048576).toFixed(2)} MB`;
    },
    filterDuration(duration) {
      let result = 0;
      if (duration) {
        let minutes = parseInt(duration / 60);
        let seconds = parseInt(duration % 60);

        result = `${minutes}:${seconds}`;
      }

      return result;
    },
  },
  computed: {
    ...mapState(["user", "groupId"]),
    getProducts() {
      if (!this.searchProduct) return this.sortedArray(this.products);
      else
        return this.sortedArray(
          this.products.filter((item) =>
            item.name.toLowerCase().includes(this.searchProduct.toLowerCase())
          )
        );
    },
    getProductsAdded() {
      if (!this.searchProductsAdded)
        return this.sortedArray(this.productsAdded);
      else
        return this.sortedArray(
          this.productsAdded.filter((item) =>
            item.name
              .toLowerCase()
              .includes(this.searchProductsAdded.toLowerCase())
          )
        );
    },
  },

  methods: {
    sortedArray: function (data) {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      return data.sort(compare);
    },
    leftSideEdited(e) {
      if (e.removed) {
        this.products = this.products.filter(
          (item) => item[".key"] != e.removed.element[".key"]
        );
      }
    },

    showFile(file) {
      if (file.images.length && file.images[0].url) {
        this.selectedProduct = file;
        this.viewProductDialog = true;
      }
    },

    rightSideEdited(e) {
      if (e.removed) {
        this.productsAdded = this.productsAdded.filter(
          (item) => item[".key"] != e.removed.element[".key"]
        );
      }
    },

    async save() {
      this.loading = true;
      let productsAddedID = this.productsAdded.map((item) => {
        return item[".key"];
      });

      db.collection("categories")
        .doc(this.category)
        .collection("subcategories")
        .doc(this.subcategory[".key"])
        .update({
          products: productsAddedID,
        })
        .then(async (response) => {
          await this.updateProductsInfo();
          this.$emit("success");
        })
        .catch((error) => {
          this.loading = false;
          this.snackbarText =
            this.$tc('messages.unknown')
          this.snackbar = true;
        });
    },
    updateProductsInfo() {
      return new Promise(async (resolve, reject) => {
        for (let i = 0; i < this.productsAdded.length; i++) {
          const product = this.productsAdded[i];
          let data = {};
          if (
            !product.subcategories ||
            !product.subcategories.includes(this.subcategory[".key"])
          )
            data.subcategories = fb.firestore.FieldValue.arrayUnion(
              this.subcategory[".key"]
            );

          if (
            !product.categories ||
            !product.categories.includes(this.category)
          )
            data.categories = fb.firestore.FieldValue.arrayUnion(this.category);

          await db.collection("products").doc(product[".key"]).update(data);
        }

        for (let i = 0; i < this.products.length; i++) {
          const product = this.products[i];
          let data = {};
          if (
            product.subcategories &&
            product.subcategories.includes(this.subcategory[".key"])
          )
            data.subcategories = fb.firestore.FieldValue.arrayRemove(
              this.subcategory[".key"]
            );

          if (product.categories && product.categories.includes(this.category))
            data.categories = fb.firestore.FieldValue.arrayRemove(
              this.category
            );

          await db.collection("products").doc(product[".key"]).update(data);
        }

        resolve("success");
      });
    },
  },

  async mounted() {
    await this.$binding(
      "products",
      db.collection("products").where("groupId", "==", this.groupId)
    );

    console.log(this.subcategory.products);

    this.productsAdded = this.products.filter(
      (item) =>
        this.subcategory.products &&
        this.subcategory.products.includes(item[".key"])
    );
    this.products = this.products.filter(
      (item) =>
        !this.subcategory.products ||
        !this.subcategory.products.includes(item[".key"])
    );
    this.loading = false;
  },
};
</script>

<style scoped>
.list-group {
  min-width: 550px;
  min-height: 200px;
}
.cards-container {
  background-color: #eeeeee;
  border-radius: 20px;
  padding: 20px;
  min-height: 510px;
  max-height: 510px;
  overflow-y: auto;
}

.image {
  width: 100px;
  margin: 15px;
  cursor: pointer;
  object-fit: cover;
  height: 100px;
}
.icon {
  position: absolute;
  left: 0px;
  font-size: 20px;
  cursor: move;
  color: gray;
}

.duration {
  position: absolute;
  right: 20px;
  color: gray;
}

.description {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 600px) {
  .cards-container {
    padding: 10px;
    width: 100%;
    min-height: 400px;
    max-height: 400px;
    overflow-x: hidden;
  }

  .image {
    cursor: pointer;
    object-fit: cover;
    height: 60px;
    width: 60px;
    padding: 0;
    margin: 0 10px 0 0;
  }
}
</style>