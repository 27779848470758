<template>
  <div
    style="
      min-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <v-icon @click="$emit('cancel')" class="close">fas fa-times</v-icon>
    <video
      autoplay
      v-if="type == 'video'"
      controls
      class="file-size"
      :src="url"
    ></video>
    <v-img
      class="file-size"
      contain
      v-else
      :lazy-src="lazy ? lazy : ''"
      :src="url"
    >
    </v-img>
  </div>
</template>

<script>
export default {
  props: ["url", "type", "lazy"],
  name: "view-file"
}
</script>

<style scoped>
.close {
  position: absolute;
  top: 40px;
  right: 40px;
  color: white;
  font-size: 30px !important;
  font-weight: bold;
  cursor: pointer;
  border-radius: 20px;
}

.file-size {
  min-width: 70%;
  max-width: 70%;
  max-height: 70vh;
}

@media only screen and (max-width: 600px) {
  .file-size {
    min-width: 90%;
    max-width: 90%;
    max-height: 70vh;
  }
}
</style>