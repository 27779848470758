<template>
  <div>
    <v-snackbar
      content-class="centered"
      absolute
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-container fluid>
      <v-row class="header-title-actions">
        <v-col cols="12" sm="5" md="5">
          <h1 class="page-title">{{ $tc("subcategories.title") }}</h1>
        </v-col>

        <v-col class="align-end" style="align-items: center!important" cols="12" sm="7" md="7">
          <v-autocomplete
            v-model="selectedCategory"
            :items="sortedCategories"
            prepend-icon="fa-store"
            :label="$tc('subcategories.select_title')"
            aria-autocomplete="false"
            autocomplete="off"
            hide-details
            item-value=".key"
            @change="getSubcategories"
            item-text="name"
            filled
            rounded
            :allow-overflow="false"
            clearable
          >
            <template v-slot:selection="data">
              {{ data.item.name }}
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar class="mr-2">
                  <v-img
                    :lazy-src="
                      data.item.image ? data.item.image.loading : defaultImage
                    "
                    :src="
                      data.item.image ? data.item.image.original : defaultImage
                    "
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    data.item.description
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>

          <v-btn
            color="buttons"
            v-if="selectedCategory && subcategories.length > 1"
            @click="showSortDialog"
            class="ml-3"
            :loading="loading"
          >
            <v-icon left> fas fa-plus </v-icon>
            {{ $tc("sort.change_position") }}
          </v-btn>

          <v-btn
            color="buttons"
            @click="showAdd"
            class="ml-3"
            :loading="loading"
          >
            <v-icon left> fas fa-plus </v-icon>
            {{ $tc("subcategories.add") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <!-- <v-col cols="12" sm="12" md="12">
          <search :loading="loading" name="" />
        </v-col> -->
      </v-row>
      <v-row v-if="filteredItems.length == 0">
        <v-col cols="12" sm="12" md="12">
          <div class="text-center">
            {{
              selectedCategory
                ? $tc("messages.empty_search")
                : $tc("subcategories.select_category")
            }}
          </div>
        </v-col>
      </v-row>
      <v-row v-else class="ml-1 mt-5">
        <v-col
          cols="6"
          sm="3"
          md="3"
          v-for="(item, index) of filteredItems"
          :key="index"
        >
          <card
            @menuClick="handleMenuActions($event, item)"
            :name="item.name"
            :image="item.image ? item.image.original : ''"
            :items="menuItems"
            :lazy="item.image && item.image.loading ? item.image.loading : ''"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-dialog max-width="500px" v-if="deleteDialog" v-model="deleteDialog">
      <delete-item
        ref="deleteComponent"
        :name="selectedItem.name"
        @success="confirmDelete"
        @cancel="deleteDialog = false"
      />
    </v-dialog>

    <v-dialog
      v-if="addSubcategory"
      persistent
      max-width="900px"
      v-model="addSubcategory"
    >
      <new-subcategory
        :category="selectedCategory"
        :originalItem="selectedItem"
        @success="added"
        @cancel="addSubcategory = false"
      />
    </v-dialog>

    <v-dialog
      v-if="viewImageDialog"
      v-model="viewImageDialog"
      hide-overlay
      fullscreen
    >
      <view-file
        :url="selectedItem.image.original"
        type="image"
        @cancel="viewImageDialog = false"
      />
    </v-dialog>

    <v-dialog
      v-if="addProductsDialog"
      persistent
      max-width="1200px"
      v-model="addProductsDialog"
    >
      <add-products
        @success="addedSuccess"
        :category="selectedCategory"
        :subcategory="selectedItem"
        @cancel="addProductsDialog = false"
      />
    </v-dialog>

    <v-dialog
      v-if="sortDialog"
      persistent
      max-width="500px"
      v-model="sortDialog"
    >
      <sort
        :source="subcategories"
        @updatedItems="handleSortedItems"
        @success="added"
        @cancel="sortDialog = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import filesTable from "@/components/files-table";
import newSubcategory from "./add-subcategories";
import viewFile from "@/components/view-file";
import addProducts from "./add-products";
import deleteItem from "@/components/delete";
import sort from "@/components/sort";
import card from "@/components/card";

export default {
  name: "subcategories",
  components: {
    filesTable,
    newSubcategory,
    viewFile,
    addProducts,
    deleteItem,
    sort,
    card,
  },
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      loading: true,
      selectedCategory: "",
      addSubcategory: false,
      viewImageDialog: false,
      defaultImage: require("@/assets/wink-placeholder.png"),
      selectedItem: null,
      deleteDialog: false,
      sortDialog: false,
      categories: [],
      subcategories: [],
      addProductsDialog: false,
      menuItems: [
        {
          title: this.$tc("table.view_file"),
          icon: "fas fa-eye",
          action: "view_image",
        },
        { title: this.$tc("table.edit"), icon: "fas fa-edit", action: "edit" },
        {
          title: this.$tc("table.add_products"),
          icon: "fas fa-hockey-puck",
          action: "add_products",
        },
        {
          title: this.$tc("table.delete"),
          icon: "fas fa-trash",
          action: "delete",
        },
      ],
    };
  },
  computed: {
    ...mapState(["search", "groupId"]),
    sortedCategories() {
      return this.sortedCategoriesArray();
    },
    filteredItems() {
      let data = [];
      if (!this.search) data = this.subcategories;
      else
        data = this.subcategories.filter((item) =>
          item.name.toLowerCase().includes(this.search.toLowerCase())
        );
      return data;
    },
  },
  methods: {
    ...mapActions(["cleanSearch"]),
    showSortDialog() {
      if (this.selectedCategory && this.subcategories.length > 1)
        this.sortDialog = true;
    },
    sortedCategoriesArray: function () {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      return this.categories.sort(compare);
    },
    sortedByPosition: function (data) {
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }
      return data.sort(compare);
    },
    added() {
      this.addSubcategory = false;
      this.snackbarText = this.selectedItem
        ? this.$tc("subcategories.updated")
        : this.$tc("subcategories.added");
      this.snackbar = true;
    },
    addedSuccess() {
      this.addProductsDialog = false;
      this.snackbarText = this.$tc("subcategories.products_added");
      this.snackbar = true;
    },

    handleSortedItems(items) {
      for (let i = 0; i < items.length; i++) {
        let subcategory = items[i];
        db.collection("categories")
          .doc(this.selectedCategory)
          .collection("subcategories")
          .doc(subcategory[".key"])
          .update({
            position: i,
          });
      }
      this.snackbarText = this.$tc("subcategories.sorted");
      this.snackbar = true;
      this.sortDialog = false;
    },

    handleMenuActions(action, item) {
      switch (action) {
        case "view_image":
          this.viewImage(item);
          break;
        case "edit":
          this.edit(item);
          break;
        case "add_products":
          this.viewProducts(item);
          break;
        case "delete":
          this.showDeleteDialog(item);
          break;
      }
    },
    edit(item) {
      this.selectedItem = item;
      this.addSubcategory = true;
    },
    viewProducts(item) {
      this.selectedItem = item;
      this.addProductsDialog = true;
    },
    showDeleteDialog(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    viewImage(item) {
      this.selectedItem = item;
      this.viewImageDialog = true;
    },
    showAdd() {
      if (this.selectedCategory) {
        this.selectedItem = null;
        this.addSubcategory = true;
      } else {
        this.snackbarText = this.$t("subcategories.select_home_menu");
        this.snackbar = true;
      }
    },

    getSubcategories(categoryId) {
      if (categoryId) {
        this.loading = true;
        db.collection("categories")
          .doc(categoryId)
          .collection("subcategories")
          .onSnapshot((response) => {
            this.subcategories = [];

            response.forEach((item) => {
              let subcategory = item.data();
              subcategory[".key"] = item.id;
              this.subcategories.push(subcategory);
            });
            this.subcategories = this.sortedByPosition(this.subcategories);
            this.loading = false;
          });
      } else {
        this.subcategories = [];
      }
    },

    async confirmDelete() {
      this.$refs.deleteComponent.loading = true;
      db.collection(`categories/${this.selectedCategory}/subcategories`)
        .doc(this.selectedItem[".key"])
        .delete()
        .then((response) => {
          this.snackbarText = this.$t("subcategories.deleted");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
          this.deleteDialog = false;
        })
        .catch((error) => {
          this.snackbarText = this.$tc("messages.unknown");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
        });
    },
  },
  async mounted() {
    this.cleanSearch();

    this.$binding(
      "categories",
      db
        .collection("categories")
        .where("groupId", "==", this.groupId)
        .where("type", "==", "category")
    ).then((res) => {
      // console.log(res);
      this.loading = false;
    });
  },
};
</script>