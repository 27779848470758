<template>
  <v-menu bottom>
    <template v-slot:activator="{ on }">
      <v-card class="mx-3">
        <v-img
          aspect-ratio="1.77"
          :src="image || defaultImage"
          class="white--text align-end"
          height="180px"
          gradient="to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.41)"
          v-on="on"
          :lazy-src="lazy ? lazy : ''"
          style="cursor: pointer"
        >
          <small v-if="type" class="tag">{{
            type == "survey" ? $t("tags.survey") : type == "category" ?  $t("tags.category") : ''
          }}</small>
        </v-img>

        <div class="name">
          {{ name }}
        </div>
      </v-card>
    </template>

    <v-list>
      <v-list-item
        v-for="(item, index) of items"
        :key="index"
        @click="$emit('menuClick', item.action)"
      >
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: ["image", "items", "name", "lazy", "type"],
  name: "cards",
  data() {
    return {
      defaultImage: require("@/assets/wink-placeholder.png"),
    };
  },
};
</script>

<style scoped>
.name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  justify-content: center;
  display: flex;
  padding: 10px;
  font-weight: bold;
  background-color: #eeeeee;
}

.ellipsis {
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 0;
  color: white;
  font-weight: bold;
  font-size: 25px;
  width: 50px;
  height: 50px;
  text-align: center;
}

.v-card > :first-child:not(.v-btn):not(.v-chip),
.v-card > .v-card__progress + :not(.v-btn):not(.v-chip) {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.v-card {
  border-radius: 5px !important;
}

.tag {
  position: absolute;
  top: 1px;
  right: 1px;
  background-color: #929292b6;
  padding: 4px;
  font-weight: bold;
}
</style>